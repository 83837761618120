import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ContentSection from '~/components/Content/Section';
import ContentFeatureHero from '~/components/Content/Page/ContentFeatureHero';

const Faq = ({ data }) => {
  const { page } = data;
  const { hero, mobileHero } = page;
  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor} backgroundColor={page?.backgroundColor}>
      <Metadata />
      <ContentFeatureHero hero={hero} mobileHero={mobileHero} />
      {page.sections &&
        page.sections.map((section) => (
          <ContentSection hideText={true} isFullBleed={true} key={section.id} section={section} />
        ))}
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query FaqPage($locale: String) {
    page: contentfulPage(slug: { eq: "support" }, node_locale: { eq: $locale }) {
      name
      hero {
        ...CoverFeatureFragment
        ...ContentHeroFragment
      }
      ...ContentPageFragment
    }
  }
`;
